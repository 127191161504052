<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-danger row">
                        <h3 class="card-title col-6">Kuaa-Podcast</h3>
                        <div class="card-category col-6">
                            <a  mat-raised-button type="button"  class=" btn btn-secondary pull-center" routerLinkActive="active" [routerLink]="[urlAdd]">
                                <i class="material-icons">add</i>Crear Podcast</a>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class=" text-primary">
                                    <th> Titulo </th>
                                    <th>SubTitulo</th>
                                    <th>Instructor</th>
                                    <th style="width: 20%;">Banner</th>
                                    <th>Estado</th>
                                    <!--
                                    <th>Modulo</th>
                                    <th>Status</th>
                                    -->
                                    

                                    <th>Actiones</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of objListPodcast">
                                        <td>
                                            <div class="text">
                                                {{ item.title }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text">
                                                {{ item.subtitle }}
                                            </div>
                                        </td>
                                        <td>{{item.instructorName}}</td>
                                        <td>
                                            <img src="{{item.banner1}}" alt="" style="background-color: black; width: 30%;">
                                        </td>
                                        <td>{{item.estado}}</td>
                                        <!--
                                        <td>{{item.moduleName}}</td>
                                        <td class="text-primary">
                                            {{ item.status == '1' ? 'Activo' :'Desactivado'  }}
                                        </td>
                                        -->
                                        <td class="text-primary">
                                            <a mat-raised-button type="button"   class="btn btn-info pull-center" href="#/podcast/edit/{{item.id}}" >
                                                <i _ngcontent-fok-c45="" class="material-icons">edit</i>
                                                Editar</a>
                                            |
                                            <button class="btn btn-danger pull-center" data-toggle="modal" data-target="#moduleDeletedConfirm" (click)="open( item.id )" >
                                                <i _ngcontent-fok-c45="" class="material-icons">delete</i>
                                                Eliminar
                                            </button>
                                        </td>                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete-confirm-modal -->
<div *ngIf="showModalBox" class="modal modal-angular fade" id="moduleDeletedConfirm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="margin-top">
                  Eliminar podcast  {{objPodcastDTO.title}}
                </h4>
                <div class="separator"></div>
                <div class="row" >
                    <div class="col-md-12">
                        <span> ¿Esta seguro que desea eliminar este podcast? </span>
                    </div>
                </div> 
                <div class="separator"></div>
                <div class="separator"></div>
                <div class="row" >
                    <div class="col-md-12">
                        <button mat-raised-button type="button" data-dismiss="modal"  class="btn btn-otrher pull-right">Cancelar</button>
                        <button mat-raised-button  type="button" class="btn btn-danger pull-right" (click)="goDelete()" [disabled]="isSaveProcess" >
                            <span *ngIf="!isSaveProcess" >Eliminar</span> 
                            <span  *ngIf="isSaveProcess" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span  *ngIf="isSaveProcess" > Eliminando...</span> 
                        </button> 
                    </div>
                </div>
    
                
            </div>
        </div>
    </div>
    
    </div>
import { ResponseImageDTO } from "./response-image-dto";

export class KuaaTransactionDto {
    id?:     number;
    name:    string;
    message: string; 
    code:    string;
    state:   string;
    entityTransfer: ResponseImageDTO[];
}

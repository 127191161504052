export class ModulesDto {
    id:          string;
    code:        string;
    name:        string;
    idClient:    string;
    title:       string;
    subtitle:    string;
    description: string;
    position:    number;

    banner1:     string;
    banner2:     string;
    banner3:     string;
    banner4:     string;

    class1:     string;
    class2:     string;
    class3:     string;

    classImg1:     string;
    classImg2:     string;
    classImg3:     string;

    status:      string;
}

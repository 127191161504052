import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../service/auth.service";

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    // { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    //{ path: '/user-profile', title: 'Profile',  icon:'manage_accounts', class: '' },
    { path: '/modules', title: 'Modulos',  icon:'content_paste', class: '' },
    { path: '/instructors', title: 'Instructores',  icon:'school', class: '' },
    { path: '/content', title: 'Videos',  icon:'video_settings', class: '' },
    { path: '/series', title: 'Series',  icon:'dynamic_feed', class: '' },
    { path: '/podcast', title: 'Podcast',  icon:'podcasts', class: '' },
    { path: '/articulo', title: 'Articulos',  icon:'article', class: '' },
    { path: '/payment-summary', title: 'Resumen pagos',  icon:'article', class: '' },

    //{ path: '/table-list', title: 'Modulos',  icon:'content_paste', class: '' },
    //{ path: '/typography', title: 'Contenidos',  icon:'library_books', class: '' },
    //{ path: '/icons', title: 'Instructores',  icon:'bubble_chart', class: '' },
    //{ path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
    //{ path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
    // { path: '/login', title: 'Salir',  icon:'unarchive', class: 'active-pro' },
    
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor( public authService: AuthService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ModulesComponent } from './components/modules/modules.component';
//import { InstructorsComponent } from './components/instructor/instructors/instructors.component';
import { PodcastComponent } from './components/podcast/podcast/podcast.component';
import { ArticuloComponent } from './components/articulo/articulo/articulo.component';
import { ContentComponent } from './components/content/content/content.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
//import { SeriesComponent } from './components/serie/series/series.component';
//import { ContenteditComponent } from './components/content/contentedit/contentedit.component';
//import { SerieaddComponent } from './components/serie/serieadd/serieadd.component';
//import { SerieedithComponent } from './components/serie/serieedith/serieedith.component';
//import { ContentaddComponent } from './components/content/contentadd/contentadd/contentadd.component';
//import { ArticuloeditComponent } from './components/articulo/articuloedit/articuloedit.component';
//import { PodcasteditComponent } from './components/podcast/podcastedit/podcastedit.component';

//import { InstructordrapdropComponent } from './components/instructor/instructordrapdrop/instructordrapdrop.component';
//import { InstructoreditComponent } from './components/instructor/instructoredit/instructoredit.component';
//import { ArticuloaddComponent } from './components/articulo/articuloadd/articuloadd.component';
//import { PodcastaddComponent } from './components/podcast/podcastadd/podcastadd.component';
//import { InstructoraddComponent } from './components/instructor/instructoradd/instructoradd.component'; 
//import { ModulesaddComponent } from './components/components/modules/modulesadd/modulesadd.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ModulesComponent,
    //InstructorsComponent,
    PodcastComponent,
    ArticuloComponent,
    ContentComponent,
    //SeriesComponent,
    //ContenteditComponent,
    //SerieaddComponent,
    //SerieedithComponent,
    //ContentaddComponent,
    //ArticuloeditComponent,
    //PodcasteditComponent,
    //DragDropModule,
    //InstructordrapdropComponent,
    //InstructoreditComponent,
    //ArticuloaddComponent,
    //PodcastaddComponent,
    //InstructoraddComponent, 
    //ModulesaddComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  ]
})
export class ComponentsModule { }

export class ContentDTO {
    id:             string;
    title:          string;
    subtitle:       string;
    moduleCode:     string;
    instructorCode: string;
    contentType:    string;
    format:         string;
    lenguaje:       string;
    videoBaseCode:  string;
    duration:       string;
    tags:           string;
    sinopsis:       string;
    banner1:        string;
    banner2:        string;
    banner3:        string;
    status:         number;
    durationContent: string;
    idClient: string;
    estado: string;

}

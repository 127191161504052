// import { ENV_S_CORE_SERVICE_API_V1_BASE } from '../../environments/environment';

import { ENV_S_CORE_SERVICE_API_V1_BASE } from '../../../environments/environment';
import { S_CORE_SERVICE_API_PAYMENT_BASE_URL } from '../../../environments/environment';

export class Helperutil {

    // private static S_CORE_SERVICE_API_V1_BASE  = 'https://apicore-cmlscukhsq-uc.a.run.app';
    private static S_CORE_SERVICE_API_V1_BASE  = ENV_S_CORE_SERVICE_API_V1_BASE;
    private static S_CORE_SERVICE_API_PAYMENT_BASE  = S_CORE_SERVICE_API_PAYMENT_BASE_URL;
    private static S_CORE_SERVICE_API_V1_BASE_PATH    = '/';

    public static S_CORE_SERVICE_API_V1_COURSE = 'course/';
    public static S_CORE_SERVICE_API_V1_CATEGORY = 'category/';
    public static S_CORE_SERVICE_API_V1_MODULE = 'module/';
    public static S_CORE_SERVICE_API_V1_INSTRUCTOR = 'instructor/';
    public static S_CORE_SERVICE_API_V1_SUSCRIPTOR = 'suscriptor/';
    public static S_CORE_SERVICE_API_V1_PROFESION = 'profession/';
    public static S_CORE_SERVICE_API_V1_PODCAST = 'podcast/';
    public static S_CORE_SERVICE_API_V1_ARTICLE = 'article/';
    public static S_CORE_SERVICE_API_V1_VIDEOBASE = 'videobase/';
    public static S_CORE_SERVICE_API_V1_SERIE = 'serie/';
    public static S_CORE_SERVICE_API_PAYMENT_SERIES = 'series/';
    public static S_CORE_SERVICE_API_PAYMENT_INSTRUCTORS = 'instructors/';
    public static S_CORE_SERVICE_API_PAYMENT_COURSES = 'course/';
    public static S_CORE_SERVICE_API_PAYMENT_USERS = 'users/';
    public static S_CORE_SERVICE_API_PAYMENT_PAYMENTS = 'payments/';

    public static S_CORE_SERVICE_API_V1_VIDEOBASE_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_VIDEOBASE
                                                      + 'list';

    public static S_CORE_SERVICE_API_V1_COURSE_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'listadmin';

    public static S_CORE_SERVICE_API_V1_COURSE_FIND_BY_CONTENT_TYPE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'findByContenType';

    public static S_CORE_SERVICE_API_V1_COURSE_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'create';

    public static S_CORE_SERVICE_API_V1_COURSE_UPDATE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'update';

    public static S_CORE_SERVICE_API_V1_COURSE_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'findbyid';

    public static S_CORE_SERVICE_API_V1_COURSE_IMG_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'img/add/'; 

    public static S_CORE_SERVICE_API_V1_COURSE_GETBYCATEGORY= Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'getbycategory'; 
                                                      
    public static S_CORE_SERVICE_API_V1_COURSE_GETCONTENT= Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'getcontent';

    public static S_CORE_SERVICE_API_V1_COURSE__COMPLETE_VIDEOBASE_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'videobase/findbyid';


    public static S_CORE_SERVICE_API_V1_MODULE_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_MODULE
                                                      + 'list';
                                                      
    public static S_CORE_SERVICE_API_V1_MODULE_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_MODULE
                                                      + 'findbyid';  
                                                      
    public static S_CORE_SERVICE_API_V1_MODULE_CREATE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_MODULE
                                                      + 'add';

    public static S_CORE_SERVICE_API_V1_MODULE_UPDATE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_MODULE
                                                      + 'update';

    public static S_CORE_SERVICE_API_V1_MODULE_DELETE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_MODULE
                                                      + 'delete';
                                                      
    public static S_CORE_SERVICE_API_V1_MODULE_IMG_ADD = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_MODULE
                                                      + 'img/add/';
    /** Instructor */
    public static S_CORE_SERVICE_API_V1_INSTRUCTOR_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'list';

    public static S_CORE_SERVICE_API_V1_INSTRUCTOR_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'findbyid';  

    public static S_CORE_SERVICE_API_V1_INSTRUCTOR_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'add';  

    public static  S_CORE_SERVICE_API_V1_INSTRUCTOR_UPDATE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'update';  

    public static  S_CORE_SERVICE_API_V1_INSTRUCTOR_DELETE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'delete';


    public static S_CORE_SERVICE_API_V1_INSTRUCTOR_IMG_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'img/add/';


    public static S_CORE_SERVICE_API_V1_INSTRUCTOR_SET_ORDER = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'setorder';

    public static S_CORE_SERVICE_API_V1_INSTRUCTOR_LIST_BY_SEQUENCE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'list/by/sequence';

    public static S_CORE_SERVICE_API_V1_INSTRUCTOR_FIND_BY_MODULE_ID = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'findby/moduleid';
                                                      
  /** Profession */
  public static S_CORE_SERVICE_API_V1_PROFESSION_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PROFESION
                                                      + 'list';   
                                                      
  /** Podcast */
  public static S_CORE_SERVICE_API_V1_PODCAST_IMG_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PODCAST
                                                      + 'img/add/'; 

  public static S_CORE_SERVICE_API_V1_PODCAST_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PODCAST
                                                      + 'add';

  public static S_CORE_SERVICE_API_V1_PODCAST_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PODCAST
                                                      + 'list';

  public static S_CORE_SERVICE_API_V1_PODCAST_LIST_FROM_SERIE_CONTENT = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PODCAST
                                                      + 'findAllFromSerieContent';

  public static S_CORE_SERVICE_API_V1_PODCAST_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PODCAST
                                                      + 'findbyid';

  public static S_CORE_SERVICE_API_V1_PODCAST_UPDATE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PODCAST
                                                      + 'update';

  public static S_CORE_SERVICE_API_V1_PODCAST_DELETE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PODCAST
                                                      + 'delete';                                                                                                            

                                                      

  /** Article */
  public static S_CORE_SERVICE_API_V1_ARTICLE_IMG_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_ARTICLE
                                                      + 'img/add/'; 

  public static S_CORE_SERVICE_API_V1_ARTICLE_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_ARTICLE
                                                      + 'add';

  public static S_CORE_SERVICE_API_V1_ARTICLE_UPDATE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_ARTICLE
                                                      + 'update';

  public static S_CORE_SERVICE_API_V1_ARTICLE_DELETE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_ARTICLE
                                                      + 'delete';                                                      

                                                      

  public static S_CORE_SERVICE_API_V1_ARTICLE_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_ARTICLE
                                                      + 'list';

 public static S_CORE_SERVICE_API_V1_ARTICLE_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_ARTICLE
                                                      + 'findbyid';                                                      

                                                      
    //public static S_CORE_SERVICE_API_V1_COURSE_STREAMING= 'http://192.168.1.20:8081/video/streaming/';                                                 
    public static S_CORE_SERVICE_API_V1_COURSE_STREAMING= 'https://apistream-cmlscukhsq-uc.a.run.app/video/streaming/';
    //public static S_CORE_SERVICE_API_V1_COURSE_STREAMING= 'http://192.168.1.24:8080/streaming/';
    //public static S_CORE_SERVICE_API_V1_COURSE_STREAMING= 'http://192.168.1.21:8080/video/';
    
                                                      
    public static S_CORE_SERVICE_API_V1_COURSE_Download = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'videos';                                                       
                                                      
                                              
    /** Category **/                                                      
    public static S_CORE_SERVICE_API_V1_CATEGORY_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_CATEGORY
                                                      + 'list';
    public static S_CORE_SERVICE_API_V1_CATEGORY_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_CATEGORY
                                                      + 'create';
    public static S_CORE_SERVICE_API_V1_CATEGORY_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_CATEGORY
                                                      + 'findbyid';                                                      
    public static S_CORE_SERVICE_API_V1_CATEGORY_IMG_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_CATEGORY
                                                      + 'img/add/'; 

    public static S_CORE_SERVICE_API_V1_SUSCRIPTOR_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_SUSCRIPTOR
                                                      + 'findbyid';

    public static S_CORE_SERVICE_API_V1_SUSCRIPTOR_UPDATE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_SUSCRIPTOR
                                                      + 'update';

    /** Serie */
    public static S_CORE_SERVICE_API_V1_SERIE_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                    + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                    + Helperutil.S_CORE_SERVICE_API_V1_SERIE
                                                    + 'listadmin';

    public static S_CORE_SERVICE_API_V1_SERIE_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                    + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                    + Helperutil.S_CORE_SERVICE_API_V1_SERIE
                                                    + 'create';

                                                    
    public static S_CORE_SERVICE_API_V1_SERIE_UPDATE = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                    + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                    + Helperutil.S_CORE_SERVICE_API_V1_SERIE
                                                    + 'update';

    public static S_CORE_SERVICE_API_V1_SERIE_IMG_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                    + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                    + Helperutil.S_CORE_SERVICE_API_V1_SERIE
                                                    + 'img/add/';

    public static S_CORE_SERVICE_API_V1_SERIE_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_SERIE
                                                      + 'findbyid';

    public static S_CORE_SERVICE_API_V1_SERIE_SAVE_CONTENT = Helperutil.S_CORE_SERVICE_API_V1_BASE 
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_SERIE
                                                      + 'saveSerieContent';                                                      


    public static S_CORE_AUTORITIE_ROL_STS    = ['{authority:"sts"}'];


    public static S_TOAS_TITLE_HEAD   = 'Kuaa';    
    public static S_TOAS_POSITIONCLASS_TOP_CENTER = 'toast-top-center';
    public static S_TOAS_POSITIONCLASS_TOP_RIGHT = 'toast-top-right';
    public static S_TOAS_POSITIONCLASS_BTN_RIGHT = 'toast-bottom-right';
    public static S_TOAS_POSITIONCLASS_BTN_CENTER = 'toast-button-center';
    public static I_TOAS_TIMEOUT = 2000;
    public static I_TOAS_TIMEOUT_PROCESS = 3000;
    public static S_SESSION_ENDED = 'Session is ended';
    public static S_SESSION_NOT_AUTORIZED = 'Unauthorized';
    public static S_SESSION_MSG_ACCESS_DENIED = 'Access denied!!!';
    public static S_TOAS_DEV_IN   = 'Developing';
    public static S_TOAS_PROCESS  = 'Process...';  
    public static S_TOAS_VALID_UPLOAD_MINIMAL  = 'Por favor, asegurese de cargar todas las imagenes';  
    public static S_TOAS_VALID_FIELDS  = 'Por favor, digite/seleccione todos los campos.' ;  
    public static S_TOAS_VALID_FIELD_SEASON  = 'Por favor, digite temporada para todos los contenidos.' ;

    public static ESTADO_CONTENIDO_PUBLICO = 'Público';
    public static ESTADO_CONTENIDO_BORRADOR = 'Borrador';

    //Series
    public static S_CORE_SERVICE_CHANGE_ORDEN_SERIE = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_SERIES +
                                                        'change-order'

    public static S_CORE_SERVICE_GET_SERIES_ORDER = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_SERIES

    public static S_CORE_SERVICE_GET_ALL_SERIES = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_SERIES

    public static S_CORE_SERVICE_DETELE_SERIE = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_SERIES

    public static S_CORE_SERVICE_ADD_MORE_SEEN = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_SERIES +
                                                        'more-seen'

    public static S_CORE_SERVICE_GET_SERIE = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_SERIES

    public static S_CORE_SERVICE_UPDATE_SERIE = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_SERIES

    //Instructors
    public static S_CORE_SERVICE_CHANGE_ORDEN_INSTRUCTOR = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_INSTRUCTORS +
                                                        'change-order'

    public static S_CORE_SERVICE_GET_ALL_INSTRUCTORS = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_INSTRUCTORS

    public static S_CORE_SERVICE_GET_INSTRUCTOR = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_INSTRUCTORS

    public static S_CORE_SERVICE_UPDATE_INSTRUCTOR = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_INSTRUCTORS

    //Courses
    public static S_CORE_SERVICE_DETELE_COURSE =        Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_COURSES

    //Users
    public static S_CORE_SERVICE_GET_ALL_USERS =        Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_USERS + 'getAll'

    //Credit cards
    public static S_CORE_SERVICE_PAYMENT_PAYMENTS =     Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_PAYMENTS + 'user_payment'

}

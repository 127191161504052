import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
//import { Http, Headers, Response } from '@angular/http';
import { Observable, from, throwError } from 'rxjs'; 
import { Helperutil } from '../common/helper/helperutil';
import { __param } from 'tslib';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { ContentDTO } from '../model/content-dto';
import { AnyRecord } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private httpClient: HttpClient
            ,private sanitizer: DomSanitizer
            ,private objTokenService: TokenService,
            ) { }


  private getTokenSesion() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.objTokenService.getToken()
      })
    };
    return httpOptions;
  }

  public getVideoBaseList(): Observable<any[]> {
    const httpOptions = this.getTokenSesion();
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_VIDEOBASE_LIST, httpOptions);
  }

  public uploadFile(file, id): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);  
    const httpOptions = {
      headers: new HttpHeaders({ 
        Authorization: 'Bearer ' + this.objTokenService.getToken()
      })
    };
    const url = Helperutil.S_CORE_SERVICE_API_V1_COURSE_IMG_SAVE + id; 
    return this.httpClient.post<any>(url, formData, httpOptions); 
  }

  public save(contentDTO: ContentDTO): Observable<any> {
    const httpOptions = this.getTokenSesion();
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_SAVE, contentDTO, httpOptions);
  }


  public update( contentDTO: ContentDTO): Observable<any> {
    const httpOptions = this.getTokenSesion();
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_UPDATE, contentDTO, httpOptions);
  }

  public getCompleteVideoBaseFindById(id: string ): Observable<any> {
    const httpOptions = this.getTokenSesion();
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_COURSE__COMPLETE_VIDEOBASE_FINDBYID + `/${id}`, httpOptions );
  }

  public getCoursesDefault(): Observable<ContentDTO[]> {
    const httpOptions = this.getTokenSesion();
    return this.httpClient.get<ContentDTO[]>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_LIST, httpOptions);
  }


  public findByContenType( type: String): Observable<ContentDTO[]> {
    const httpOptions = this.getTokenSesion();
    return this.httpClient.get<ContentDTO[]>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_FIND_BY_CONTENT_TYPE + `/${type}`, httpOptions);
  } 
  

  public findById(id: string ): Observable<any> {
    const httpOptions = this.getTokenSesion( );
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_FINDBYID + `/${id}`, httpOptions );
  }

  
  public deleteCourse(id): Observable<any> {
    const httpOptions = this.getTokenSesion();
    return this.httpClient.delete(Helperutil.S_CORE_SERVICE_DETELE_COURSE+ `${id}`, httpOptions);
  }
  


}

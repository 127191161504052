<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-danger row">

                        <h3 class="col-6 card-title ">Kuaa-Modulos</h3>
                        <div class="col-6 text-primary card-category">
                            <a  mat-raised-button type="button"  class=" btn btn-secondary pull-center" routerLinkActive="active" [routerLink]="[urlAdd]">
                                <i class="material-icons">add</i>Crear Modulo</a>
                            <!-- <a mat-raised-button type="button"   class="btn btn-info pull-center" href="#/modules/edit/{{item.id}}" >
                                <i _ngcontent-fok-c45="" class="material-icons">edit</i>
                                Editar</a> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class=" text-primary">
                                    <th> Titulo </th>                                    
                                    <!-- <th>SubTitulo</th>                                     -->
                                    <th style="width: 20%;">Banner</th>
                                    <!--
                                    <th>Status</th>
                                    -->
                                    <th>Acciones</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of objListModules">
                                        <td style="width: 40%;">{{item.name}}</td>
                                        
                                        <!-- <td>
                                            <div class="text">
                                                {{ item.subtitle }}
                                            </div>
                                        </td> -->
                                        <td>
                                            <img src="{{item.banner1}}" alt="" style="background-color: black; width: 30%;">
                                        </td>
                                        <!--
                                        <td class="text-primary">
                                            {{ item.status == '1' ? 'Activo' :'Desactivado'  }}
                                        </td>
                                        -->
                                        <td class="text-primary">
                                            <div class="btn-group" role="group" aria-label="Basic example">
                                                <a type="button" class="btn btn-info" href="#/modules/edit/{{item.id}}">
                                                    <i _ngcontent-fok-c45="" class="material-icons">edit</i>
                                                </a>
                                                <a type="button" class="btn btn-danger" data-toggle="modal" data-target="#moduleDeletedConfirm"  (click)="open( item.id )" >
                                                    <i _ngcontent-fok-c45="" class="material-icons">delete</i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Delete-confirm-modal -->
<div *ngIf="showModalBox" class="modal modal-angular fade" id="moduleDeletedConfirm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="margin-top">
                  Eliminar el modulo  {{objModulesDto.title}}
                </h4>
                <div class="separator"></div>
                <div class="row" >
                    <div class="col-md-12">
                        <span> ¿Esta seguro que desea eliminar este modulo? </span>
                    </div>
                </div> 
                <div class="separator"></div>
                <div class="separator"></div>
                <div class="row" >
                    <div class="col-md-12">
                        <button mat-raised-button type="button" data-dismiss="modal"  class="btn btn-otrher pull-right">Cancelar</button>
                        <!--
                        <button mat-raised-button type="button"  (click)="goDelete( )"  class="btn btn-danger pull-center">Eliminar</button>
                        -->
                        <button mat-raised-button  type="button" class="btn btn-danger pull-right" (click)="goDelete()" [disabled]="isSaveProcess" >
                            <span *ngIf="!isSaveProcess" >Eliminar</span> 
                            <span  *ngIf="isSaveProcess" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span  *ngIf="isSaveProcess" > Eliminando...</span> 
                        </button> 
                    </div>
                </div>
    
                
            </div>
        </div>
    </div>
    
    </div>
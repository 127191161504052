import { Injectable, NgZone } from '@angular/core';
import { User } from "../service/user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { TokenService } from './token.service';
import { Helperutil } from '../common/helper/helperutil';



@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private tokenService: TokenService
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      //console.log(' constructor AuthService: ' + user );
      if (user) {
        this.userData = user;
        //this.userData.idToken= user.xa;

        this.userData = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          idToken: JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken
        }
        /*
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        })
        */
        //console.log('this.userData')
        //console.log(this.userData)
        //localStorage.setItem('user', JSON.stringify(this.userData));
        //JSON.parse(localStorage.getItem('user'));
      } else {
        //localStorage.setItem('user', null);
        //JSON.parse(localStorage.getItem('user'));
        this.router.navigate(['login']);
      }

    })
  }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      var user = this.afAuth.auth.onAuthStateChanged(function (user) {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      })
    })
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          // this.router.navigate(['home']);
          //this.tokenService.setToken(JSON.parse(JSON.stringify(result.user)).stsTokenManager.accessToken );
          //this.tokenService.setToken( result.user.toJSON().stsTokenManager.accessToken );
          //this.tokenService.setUserName( result.user.email );
          //this.tokenService.setAuthorities(  Helperutil.S_CORE_AUTORITIE_ROL_STS );

          this.afAuth.idTokenResult.subscribe((user: any) => {
            //localStorage.setItem('bearerToken', user.token + '' || '');
            //resolve(this.getRole(email));
            // console.log('dat: ', user.claims.role_super);
            if (user.claims.role_super) {
              //const expirationDate = new Date(user.expirationTime);
              //localStorage.setItem('expirationTime', expirationDate+"");
              this.tokenService.setExpirationTime(user.expirationTime);
              this.tokenService.setToken(user.token);
              this.tokenService.setUserName(user.claims.email);
              this.tokenService.setAuthorities(Helperutil.S_CORE_AUTORITIE_ROL_STS);
              this.router.navigate(['home']);
            }

            this.tokenService.setUserName(user.claims.email);
          });
        });
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        this.router.navigate(['verify-email-address']);
      })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    //isLoggedIn()  {
    //const user = JSON.parse(localStorage.getItem('user'));
    //const user = JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken;

    const user = this.userData;

    this.afAuth.authState.subscribe(user => {
      console.log('isLoggedIn user: ' + user);
      return true;
    });
    //return (user !== null && user.emailVerified !== false) ? true : false;
    console.log('user: ' + user);
    const isIn = (user !== undefined) ? true : false;
    console.log('isIn: ' + isIn);
    return isIn;
  }


  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['home']);
          this.afAuth.idTokenResult.subscribe((user: any) => {
            console.log(user)
            this.tokenService.setToken(user.token);
            this.tokenService.setUserName(user.claims.email);
            this.tokenService.setAuthorities(Helperutil.S_CORE_AUTORITIE_ROL_STS);
            this.router.navigate(['home']);

          });
        })
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    //console.log('user');
    //console.log(user.xa);
    //console.log(user);
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      idToken: user.xa
    }
    //console.log(userData);

    return userRef.set(userData, {
      merge: true
    });
    //return userData;

  }

  // Sign out
  SignOut() {
    console.log('SignOut ');
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.tokenService.logOut();
      this.router.navigate(['login']);
    })
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
//import { Http, Headers, Response } from '@angular/http';
import { Observable, from, throwError } from 'rxjs';
import { ModulesDto } from '../model/modules-dto';
import { Helperutil } from '../common/helper/helperutil';
import { __param } from 'tslib';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { catchError, map, retry, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(private httpClient: HttpClient,private sanitizer: DomSanitizer) { }

  private getTokenSesion(sToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return httpOptions;
  }

  public getCoursesDefault(sToken): Observable<ModulesDto[]> {
    //console.log('url: ')
    //console.log(Helperutil.S_CORE_SERVICE_API_V1_COURSE_LIST)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return this.httpClient.get<ModulesDto[]>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_LIST, httpOptions);
  }

  public saveModule(moduleDTO: ModulesDto, sToken): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_MODULE_CREATE, moduleDTO, httpOptions);
  }


  public updateModule(moduleDTO: ModulesDto, sToken): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_MODULE_UPDATE, moduleDTO, httpOptions);
  }

  public deleteModule(moduleDTO: ModulesDto, sToken): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_MODULE_DELETE, moduleDTO, httpOptions);
  }
  
  public findById(id: string, sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_MODULE_FINDBYID + `/${id}`, httpOptions );
  }

  downloadFile(uel: string): Observable<Blob> { 
    const headers = new Headers();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*');
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods':'GET',
        'Access-Control-Allow-Origin':'*',        
      }),
      responseType: 'blob'
    };
    
    return this.httpClient.get(uel, { headers:  new HttpHeaders({
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods':'GET',
      'Access-Control-Allow-Origin':'*',        
    }), responseType: 'blob' } );
}

  public getContentDownload(code: string, sToken: string)  {
    //const httpOptions = this.getTokenSesion(sToken);
    let url = Helperutil.S_CORE_SERVICE_API_V1_COURSE_Download + `/${code}`;
    console.log('url: ', url );
    //return this.httpClient.get<any>(url, httpOptions );
    //let options = new RequestOptions({responseType: ResponseContentType.Blob });

    const httpOptions = {
      headers: new HttpHeaders({
        /*
        'Content-Type': 'video/mp4',
        'Content-Transfer-Encoding': 'binary',
        'Accept-Ranges': 'bytes',
        'Connection':'keep-alive',
        */
        //'Accept-Ranges':'bytes',
        //'Keep-Alive': 'timeout=60',
        //'Content-Type': 'video/mp4',
        //'Content-Transfer-Encoding':'binary',
        //'Accept': 'video/mp4',
         //'transfer-encoding': 'chunked',
        //'redirect': 'follow',
        //'responseType':'blob', 
        //'responseType': ResponseContentType.Blob,
        //'reportProgress': 'true',
        //'vary': 'Origin, Access-Control-Request-Method, Access-Control-Request-Headers',
        //'connection': 'keep-alive',
        Authorization: 'Bearer ' + sToken
      })
    };
    //return this.httpClient.get(url, httpOptions );
    return this.httpClient.get<any>(url, httpOptions ).pipe(
      retry(1), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code  `+error.status);
      console.error(`body was: `+ error.message );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }



  public getImage(url: string): Observable<SafeResourceUrl> {
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .pipe(
        map(x => {
          const urlToBlob = window.URL.createObjectURL(x) // get a URL for the blob
          return this.sanitizer.bypassSecurityTrustResourceUrl(urlToBlob); // tell Anuglar to trust this value
        }),
      );
  }


  public uploadFile(file, sToken, id): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const httpParams = new HttpParams();
    httpParams.append('file', file);
    //formData.append('file', this.selectedFile, this.selectedFile.name);
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type':  'multipart/form-data; boundary=<calculated when request is sent>',
        Authorization: 'Bearer ' + sToken
      })
    };
    
    console.log('\n id: '+id);
    
    const url = Helperutil.S_CORE_SERVICE_API_V1_MODULE_IMG_ADD + id;
    /*
    console.log('url: ', url);
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json' 
    });
    */
    return this.httpClient.post<any>(url, formData, httpOptions);
    //return this.httpClient.request(req);
    /*
    return this.httpClient.post<any>(url, formData, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + sToken
        }),
        reportProgress: true,
        responseType: 'json',
        params: httpParams
      } );
    */

  }

  public getByCategory(sToken, code: string): Observable<any[]> { 
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_GETBYCATEGORY+ `/${code}`, httpOptions);
  }

  public getModuleList(sToken, code: string): Observable<any[]> { 
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_MODULE_LIST, httpOptions);
  }



  public getContent(sToken, code: string): Observable<any[]> { 
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_GETCONTENT+ `/${code}`, httpOptions);
  }


  public getContentKuaa( code: string ): Observable<any[]> { 
    const httpOptions = {
      headers: new HttpHeaders({})
    };
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_STREAMING+ `/${code}`, httpOptions);
  }
  

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  public searchData(field, obj, dataObj, section = 'serie') {
    let input = <HTMLInputElement>document.getElementById(field)

    let data = []
    obj = []
    dataObj.forEach(element => {
      if (this.searchInputDataOnSection(section, element, input.value) >= 0) {
        data.push(element)
      }
    })
    return data
  }

  public searchInputDataOnSection(section, element, input) {
    const ExpReg = new RegExp(input.toLowerCase());
    let index
    switch (section) {
      case 'serie':
        index = element.title.toLowerCase().search(ExpReg)
        break
      case 'instructors':
        let name = `${element.firstName} ${element.lastName} ${element.surname} ${element.secondSurname}`
        index = name.toLowerCase().search(ExpReg)
        break
      case 'payment':
        index = element.email.toLowerCase().search(ExpReg)
        break
    }
    return index
  }

  public searchMostSeen(value, dataObj) {
    let data = []
    dataObj.forEach(element => {
      switch (value) {
        case 'more':
          if (element.moreSeen) {
            data.push(element)
          }
          break;
        case 'without':
          if (element.moreSeen == undefined) {
            data.push(element)
          }
          break
        default:
          data.push(element)
          break
      }
    })
    return data
  }

  public searchStatus(value, dataObj) {
    let data = []
    dataObj.forEach(element => {
      switch (value) {
        case 'public':
          if (element.estado == 'Público') {
            data.push(element)
          }
          break;
        case 'draft':
          if (element.estado == 'Borrador') {
            data.push(element)
          }
          break
        default:
          data.push(element)
          break
      }
    })
    return data
  }

  public searchStatusInstructor(value, dataObj){
    let data = []
    dataObj.forEach(element => {
      switch (value) {
        case 'active':
          if (element.status == '1') {
            data.push(element)
          }
          break;
        case 'inactive':
          if (element.status == '0') {
            data.push(element)
          }
          break
        default:
          data.push(element)
          break
      }
    })
    return data
  }

  public searchPaymentPlan(value, dataObj){
    let data = []
    dataObj.forEach(element => {
      switch (value) {
        case 'without_plan':
          if (element.plan == 'Sin plan') {
            data.push(element)
          }
          break;
        case 'monthly':
          if (element.plan == 'Mensual') {
            data.push(element)
          }
          break
        case 'annual':
          if (element.plan == 'Anual') {
            data.push(element)
          }
          break
        default:
          data.push(element)
          break
      }
    })
    return data
  }

  public searchStatusPayment(value, dataObj){
    let data = []
    dataObj.forEach(element => {
      switch (value) {
        case 'active':
          if (element.status == 'Activo') {
            data.push(element)
          }
          break;
        case 'inactive':
          if (element.status == 'Inactivo') {
            data.push(element)
          }
          break
        case 'cancel':
          if (element.status == 'Cancelado') {
            data.push(element)
          }
          break
        default:
          data.push(element)
          break
      }
    })
    return data
  }

}

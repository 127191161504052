import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../../../service/auth.service";
import { Router } from "@angular/router";
import { TokenService } from '../../../../service/token.service';
import { PodcastDTO } from '../../../../model/podcast-dto';
import { PodcastService } from '../../../../service/podcast.service';
import { KuaaTransactionGenericDto } from '../../../../model/kuaa-transaction-generic-dto';
import { ToastrService } from 'ngx-toastr';
import { Helperutil } from '../../../../common/helper/helperutil';
import { map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { KuaaTransactionDto } from '../../../../model/kuaa-transaction-dto';


let urlAdd;

@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.css']
})
export class PodcastComponent implements OnInit {

  urlAdd = 'podcastadd';
  objListPodcast: PodcastDTO[] = [];
  showModalBox: boolean = false;
  objPodcastDTO: PodcastDTO = new PodcastDTO();
  objKuaaTransactionGenericDto: KuaaTransactionGenericDto = new KuaaTransactionGenericDto();
  objKuaaTransactionDto: KuaaTransactionDto = new KuaaTransactionDto();
  entityTransfer: PodcastDTO[] = [];
  isSaveProcess: boolean = false;

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private objPodcastService: PodcastService,
    private tokenService: TokenService,
    private toastr: ToastrService
  ) {
  }
  ngOnInit(): void {
    this.loadDefault();
  }

  loadDefault(): void {
    const sToken = this.tokenService.getToken();
    this.objPodcastService.getListDefault(sToken).subscribe(
      data => {
        this.objListPodcast = data;
      },
      err => {
        console.log(err);
      }
    );
  };

  public open(code) {
    console.log('code: ' + code);
    if (!code) {
      this.showModalBox = false;
    } else {
      this.loadDataFormConfirmDelete(code);
    }
  }

  loadDataFormConfirmDelete(code) {
    const sToken = this.tokenService.getToken();
    this.objPodcastService.findById(code, sToken).subscribe(
      data => {
        this.objKuaaTransactionGenericDto = data;
        if (this.objKuaaTransactionGenericDto.code == '00000') {
          this.entityTransfer = [];
          this.entityTransfer = this.objKuaaTransactionGenericDto.entityTransfer;
          this.objPodcastDTO = new PodcastDTO();
          this.objPodcastDTO = this.entityTransfer[0];
          this.showModalBox = true;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  goDelete() {
    this.toastr.clear();
    this.toastr.info(Helperutil.S_TOAS_PROCESS, Helperutil.S_TOAS_TITLE_HEAD, {
      timeOut: Helperutil.I_TOAS_TIMEOUT_PROCESS, positionClass: Helperutil.S_TOAS_POSITIONCLASS_TOP_RIGHT
    });
    const sToken = this.tokenService.getToken();
    this.isSaveProcess = true;
    const http$ = this.objPodcastService.delete(this.objPodcastDTO, sToken);
    http$.pipe(
      map(el => {
        this.objKuaaTransactionDto = el;
        if (this.objKuaaTransactionDto.code == '00000') {
          this.toastr.clear();
          this.toastr.success(this.objKuaaTransactionDto.message, Helperutil.S_TOAS_TITLE_HEAD, {
            timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_TOP_RIGHT
          });
          setTimeout(() => {
            //this.router.navigate(['/modules']);
            window.location.reload();
            //this.isSaveProcess = false;
          }, 1000);
        }
        return el;
      })).subscribe(el => console.log(el),
        err => {
          if (err.error.error == Helperutil.S_SESSION_NOT_AUTORIZED) {
            this.isSaveProcess = false;
          } else {
            this.toastr.warning(err.error.mensaje, Helperutil.S_TOAS_TITLE_HEAD, {
              timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_BTN_RIGHT
            });
          }
        },
        () => console.log("Processing Complete.")
      );
  }

}

import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './service/authguard';

const routes: Routes =[
  

  { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent}
  //,{ path: '', redirectTo: 'dashboard', pathMatch: 'full', }  
  ,{ path: 'home', redirectTo: 'modules', pathMatch: 'full', canActivate: [AuthGuard]  }
  ,{ path: '', component: AdminLayoutComponent
    ,children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [RouterModule
  ],
})
export class AppRoutingModule { }

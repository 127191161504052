import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';




const TOKEN_KEY = 'AuthToken';
const USERNAME_KEY = 'AuthUserName';
const AUTHORITIES_KEY = 'AuthAuthorities';
const KEY_expirationTime = 'expirationTime';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  key!: string;
  roles: Array<string> = [];

  constructor(private storageService: StorageService) { }


  public setToken(token: string): void {
    //window.sessionStorage.removeItem(TOKEN_KEY);
    //this.clearToken();
    //window.sessionStorage.setItem(TOKEN_KEY, token);

    var expires = (24*60*60);  // default: seconds for 1 day
    var now = Date.now();  //millisecs since epoch time, lets deal only with integer
    var schedule = now + expires*1000;
    //localStorage.setItem(KEY_expirationTime, schedule+'' );
    this.storageService.secureStorage.setItem(TOKEN_KEY, token);
  }

  public setExpirationTime(schedule: string): void {
    localStorage.setItem(KEY_expirationTime, schedule+'' );
  }

  public getExpirationTime(): void {
    localStorage.getItem( KEY_expirationTime );
  }

  public getToken(): string {
    //return sessionStorage.getItem(TOKEN_KEY);
    return this.storageService.secureStorage.getItem(TOKEN_KEY);
  }

  public isValidToken(): boolean {
    //console.log( 'is valid metohd' );
    const currentDate = new Date();
    //console.log( 'currentDate: ', currentDate );
    const expirationDate = new Date( localStorage.getItem( KEY_expirationTime ) || '' );
    //console.log( 'expirationDate: ', expirationDate );
    const bearerToken = this.getToken();
    if (!bearerToken || bearerToken === '' || currentDate > expirationDate) {
      return false;
    }
    return true;
  }



  clearToken() {
    return this.storageService.secureStorage.clear();
  }

  public getExpectedRol(): string[] {
    return ['sts','dev'];

  }

  public setUserName(userName: string): void {
    //window.sessionStorage.removeItem(USERNAME_KEY);
    //window.sessionStorage.setItem(USERNAME_KEY, userName);
    //this.clearToken();
    this.storageService.secureStorage.setItem(USERNAME_KEY, userName);
  }

  public getUserName(): string {
    //return sessionStorage.getItem(USERNAME_KEY);
    return this.storageService.secureStorage.getItem(USERNAME_KEY);
  }

  public setAuthorities(authorities: string[]): void {
    //window.sessionStorage.removeItem(AUTHORITIES_KEY);
    //window.sessionStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
    //this.clearToken();
    this.storageService.secureStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  public getAuthorities(): string[] {
    this.roles = [];
    if (sessionStorage.getItem(AUTHORITIES_KEY)) {
      JSON.parse(sessionStorage.getItem(AUTHORITIES_KEY)).forEach(authority => {
        this.roles.push(authority.authority);
      });
    }
    return this.roles;
  }

  public logOut(): void {
    window.sessionStorage.clear();
    this.clearToken();
  }
}

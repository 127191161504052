<div class="logo">
    <a href="/" class="simple-text">
        <div class="logo-img">
            <img src="/assets/img/logo_header.png"/>
        </div> 
    </a>
</div>
<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
    <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form>
    <!-- <ul class="nav navbar-nav nav-mobile-menu">
        <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)">
                <i class="material-icons">dashboard</i>
                <p>
                    <span class="d-lg-none d-md-block">Stats</span>
                </p>
            </a>
        </li>
        <li class="nav-item dropdown">
            <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">notifications</i>
                <span class="notification">5</span>
                <p>
                    <span class="d-lg-none d-md-block">Some Actions</span>
                </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="#">Data 1</a>
                <a class="dropdown-item" href="#">Data 2</a>
                <a class="dropdown-item" href="#">Data 3</a>
                <a class="dropdown-item" href="#">Data 4</a>
                <a class="dropdown-item" href="#">Data 5</a>
            </div>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)">
                <i class="material-icons">person</i>
                <p>
                    <span class="d-lg-none d-md-block">Account</span>
                </p>
            </a>
        </li>
    </ul> -->
  </div>
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a class="nav-link" [routerLink]="[menuItem.path]">
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
        <li routerLinkActive="active" class="active-pro nav-item">
            <a (click)="authService.SignOut()" class="nav-link">
                <i class="material-icons">unarchive</i>
                <p>Salir</p>
            </a>
        </li>
    </ul>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
//import { Http, Headers, Response } from '@angular/http';
import { Observable, from, throwError } from 'rxjs';
import { ArticleDTO } from '../model/article-dto';
import { Helperutil } from '../common/helper/helperutil';
import { __param } from 'tslib';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { catchError, map, retry, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private httpClient: HttpClient,private sanitizer: DomSanitizer) { }

  private getTokenSesion(sToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return httpOptions;
  }

  public uploadFile(file, sToken, id): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const httpParams = new HttpParams();
    httpParams.append('file', file); 
    const httpOptions = {
      headers: new HttpHeaders({ 
        Authorization: 'Bearer ' + sToken
      })
    };
    const url = Helperutil.S_CORE_SERVICE_API_V1_ARTICLE_IMG_SAVE + id; 
    return this.httpClient.post<any>(url, formData, httpOptions); 
  }

  public save( articleDTO: ArticleDTO, sToken): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_ARTICLE_SAVE, articleDTO, httpOptions);
  }

  public update( articleDTO: ArticleDTO, sToken): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_ARTICLE_UPDATE, articleDTO, httpOptions);
  }

  public getListDefault(sToken): Observable<ArticleDTO[]> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.get<ArticleDTO[]>(Helperutil.S_CORE_SERVICE_API_V1_ARTICLE_LIST, httpOptions);
  }

  public findById(id: string, sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_ARTICLE_FINDBYID + `/${id}`, httpOptions );
  }

  public delete( articleDTO: ArticleDTO, sToken): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_ARTICLE_DELETE, articleDTO, httpOptions);
  }



}

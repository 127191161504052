import { Component, OnInit } from '@angular/core';
import { ContentDTO } from '../../../../model/content-dto';
import { ContentService } from '../../../../service/content.service';
import { SearchService } from '../../../../service/search.service'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  urlAdd = 'contentadd';
  objListContentDTO: ContentDTO[] = [];
  data: ContentDTO[] = [];
  objContentDTO: ContentDTO = new ContentDTO();


  constructor(
    public objContentService: ContentService,
    private objSearchService: SearchService,
  ) { }

  ngOnInit(): void {
    this.loadDefault();
  }


  public loadDefault(): void {
    this.objListContentDTO = []
    this.objContentService.getCoursesDefault().subscribe(
      data => {
        this.objListContentDTO = data;
        this.data = data;
      },
      err => {
        console.log(err);
      }
    );
  }

  public deleteVideo(data) {
    if (data.estado == 'Público') {
      Swal.fire({
        title: 'No se puede eliminar el video',
        html: 'La video se encuentra en estado <b>público</b>, debe cambiar el estado del video para poder eliminarlo. ',
        icon: 'info',
        confirmButtonText: 'Entendido',
        confirmButtonColor: '#00aec5'
      })
    } else {
      Swal.fire({
        title: 'Eliminación de video',
        html: `¿Está seguro que desea eliminar el video <b>${data.title}</b>?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No',
        confirmButtonColor: '#f33527',
        cancelButtonColor: '#00aec5',
      }).then((result) => {
        if (result.isConfirmed) {
          this.objContentService.deleteCourse(data.id).subscribe(
            response => {
              this.loadDefault()
              Swal.fire({
                title: 'Contenido eliminado',
                html: 'El contenido se eliminó correctamente.',
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#00aec5'
              })
            }
          )
        }
      })
    }
  }

  public searchData(field) {
    this.objListContentDTO = this.objSearchService.searchData(field, this.objListContentDTO, this.data)
  }

  public searchSelect(field) {
    let select = <HTMLSelectElement>document.getElementById(field)
    this.objListContentDTO = []
    this.objListContentDTO = this.objSearchService.searchStatus(select.value, this.data)
  }


}

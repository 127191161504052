<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-danger row">
                        <h3 class="card-title col-6">Kuaa-Contenido</h3>
                        <div class="card-category col-6">
                            <!-- <a routerLinkActive="active" [routerLink]="[urlAdd]">aqui.</a> -->
                            <a  mat-raised-button type="button"  class=" btn btn-secondary pull-center" routerLinkActive="active" [routerLink]="[urlAdd]">
                                <i class="material-icons">add</i>Crear Contenido</a>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class=" text-primary">
                                    <th> Titulo </th>
                                    <!-- <th>SubTitulo</th> -->
                                    <th style="width: 20%;">Banner</th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </thead>
                                 
                                <tbody>
                                    <tr>
                                        <th> <input class="form-control-search" id="searchTitle" placeholder="Búscar por titulo" (input)="searchData('searchTitle')" > </th>
                                        <th></th>
                                        <th>
                                            <select class="form-control-search" id="status" (change)="searchSelect('status')" >
                                                <option value="" disabled selected hidden>Búscar</option>
                                                <option value="all">Todos</option>
                                                <option value="public">Público</option>
                                                <option value="draft">Borrador</option>
                                            </select>
                                        </th>
                                        <th></th>
                                    </tr>
                                    <tr *ngFor="let item of objListContentDTO">
                                        <td style="width: 40% !important;">{{item.title}}</td>
                                        <!-- <td>{{item.subtitle}}</td> -->
                                        <td  style="width: 40px !important;">
                                            <img src="{{item.banner1}}" alt="" style="background-color: black; width: 30%;">
                                        </td>
                                        <td  style="width: 30px !important;">{{item.estado}}</td>
                                        <td class="text-primary"  style="width: 43px !important;" >
                                            <div class="btn-group" role="group" aria-label="Basic example">
                                                <a type="button" class="btn btn-info" href="#/content/edit/{{item.id}}">
                                                    <i _ngcontent-fok-c45="" class="material-icons">edit</i>
                                                </a>
                                                <a type="button" class="btn btn-danger" (click)="deleteVideo(item)" >
                                                    <i _ngcontent-fok-c45="" class="material-icons">delete</i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody> 
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
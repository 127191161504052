import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../service/auth.service";
import { TokenService } from '../service/token.service';
import { devActive } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  documentdata = '';

  devActive = devActive;
  constructor(
    public authService: AuthService,
    public router: Router,
    private tokenService: TokenService,
  ) {
    const roles = this.tokenService.getAuthorities();
    if (this.tokenService.getToken() ) {
      this.router.navigate(['/home']);
    }

    //this.documentdata =  "Height: "+window.screen.height +" Width: "+window.screen.width;
    this.documentdata = " Width: "+window.innerWidth + "  Height: "+window.innerHeight ;

    console.log(this.documentdata);


   }

  ngOnInit(): void {
  }

}

import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../../../service/auth.service";
import { Router } from "@angular/router";
import { TokenService } from '../../../../service/token.service';
import { ArticleDTO } from '../../../../model/article-dto';
import { ArticleService } from '../../../../service/article.service';
import { KuaaTransactionGenericDto } from '../../../../model/kuaa-transaction-generic-dto';
import { ToastrService } from 'ngx-toastr';
import { Helperutil } from '../../../../common/helper/helperutil';
import { map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { KuaaTransactionDto } from '../../../../model/kuaa-transaction-dto';


let urlAdd;

@Component({
  selector: 'app-articulo',
  templateUrl: './articulo.component.html',
  styleUrls: ['./articulo.component.css']
})
export class ArticuloComponent implements OnInit {

  urlAdd ='articuloadd'
  objListArticleDTO: ArticleDTO[] = [];
  showModalBox: boolean = false;
  objKuaaTransactionGenericDto: KuaaTransactionGenericDto = new KuaaTransactionGenericDto();
  objKuaaTransactionDto: KuaaTransactionDto = new KuaaTransactionDto();
  entityTransfer: ArticleDTO[] = [];
  objArticleDTO: ArticleDTO = new ArticleDTO();
  isSaveProcess: boolean = false;

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private objArticleService: ArticleService,
    private tokenService:TokenService,
    private toastr: ToastrService
  ) {
  }
  ngOnInit(): void {
    this.loadDefault();
  }

  loadDefault(): void{
    const sToken = this.tokenService.getToken();
     this.objArticleService.getListDefault( sToken ).subscribe( 
       data => {
           this.objListArticleDTO = data ;
         },
         err => {
           console.log(err);
         }
     );
   };

   public open(code) {
    if (!code) {
      this.showModalBox = false;
    } else {
      this.objArticleDTO = new ArticleDTO();
      this.loadDataFormConfirmDelete(code);
    }
  }

  loadDataFormConfirmDelete(code) {
    const sToken = this.tokenService.getToken();
    this.objArticleService.findById(code, sToken).subscribe(
      data => {
        this.objKuaaTransactionGenericDto = data;
        if (this.objKuaaTransactionGenericDto.code == '00000') {
          this.entityTransfer = [];
          this.entityTransfer = this.objKuaaTransactionGenericDto.entityTransfer;
          this.objArticleDTO = new ArticleDTO();
          this.objArticleDTO = this.entityTransfer[0];
          this.showModalBox = true;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  goDelete() {
    this.toastr.clear();
    this.toastr.info(Helperutil.S_TOAS_PROCESS, Helperutil.S_TOAS_TITLE_HEAD, {
      timeOut: Helperutil.I_TOAS_TIMEOUT_PROCESS, positionClass: Helperutil.S_TOAS_POSITIONCLASS_TOP_RIGHT
    });
    const sToken = this.tokenService.getToken();
    this.isSaveProcess = true;
    const http$ = this.objArticleService.delete(this.objArticleDTO, sToken);
    http$.pipe(
      map(el => {
        this.objKuaaTransactionDto = el;
        if (this.objKuaaTransactionDto.code == '00000') {
          this.toastr.clear();
          this.toastr.success(this.objKuaaTransactionDto.message, Helperutil.S_TOAS_TITLE_HEAD, {
            timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_TOP_RIGHT
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        return el;
      })).subscribe(el => console.log(el),
        err => {
          if (err.error.error == Helperutil.S_SESSION_NOT_AUTORIZED) {
            this.isSaveProcess = false;
          } else {
            this.toastr.warning(err.error.mensaje, Helperutil.S_TOAS_TITLE_HEAD, {
              timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_BTN_RIGHT
            });
          }
        },
        () => console.log("Processing Complete.")
      );
  }


}

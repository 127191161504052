import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
//import { Http, Headers, Response } from '@angular/http';
import { Observable, from, throwError } from 'rxjs';
import { PodcastDTO } from '../model/podcast-dto';
import { Helperutil } from '../common/helper/helperutil';
import { __param } from 'tslib';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { catchError, map, retry, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PodcastService {

  constructor(private httpClient: HttpClient,private sanitizer: DomSanitizer) { }

  private getTokenSesion(sToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return httpOptions;
  }

  public uploadFile(file, sToken, id): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const httpParams = new HttpParams();
    httpParams.append('file', file); 
    const httpOptions = {
      headers: new HttpHeaders({ 
        Authorization: 'Bearer ' + sToken
      })
    };
    const url = Helperutil.S_CORE_SERVICE_API_V1_PODCAST_IMG_SAVE + id; 
    return this.httpClient.post<any>(url, formData, httpOptions); 
  }

  public save( podcastDTO: PodcastDTO, sToken): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    console.log(podcastDTO.estado);
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_PODCAST_SAVE, podcastDTO, httpOptions);
  }

  public getListDefault(sToken): Observable<PodcastDTO[]> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.get<PodcastDTO[]>(Helperutil.S_CORE_SERVICE_API_V1_PODCAST_LIST, httpOptions);
  }

  public findById(id: string, sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_PODCAST_FINDBYID + `/${id}`, httpOptions );
  }

  public update( podcastDTO: PodcastDTO, sToken): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_PODCAST_UPDATE, podcastDTO, httpOptions);
  }

  public delete( podcastDTO: PodcastDTO, sToken): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_PODCAST_DELETE, podcastDTO, httpOptions);
  }
  

  public findAllFromSerieContent(sToken): Observable<PodcastDTO[]> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.get<PodcastDTO[]>(Helperutil.S_CORE_SERVICE_API_V1_PODCAST_LIST_FROM_SERIE_CONTENT, httpOptions);
  }

}

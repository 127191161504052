// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  cryptoKey: '00f15c26-dabf-4f77-bf05-4592198cc0f7',
  firebase: {
    apiKey: "AIzaSyDnvWdhlVBKLDnDtO5iqIkkvgpp-iTgPks",
    authDomain: "kuaadev.firebaseapp.com",
    databaseURL: "https://kuaadev-default-rtdb.firebaseio.com",
    projectId: "kuaadev",
    storageBucket: "kuaadev.appspot.com",
    messagingSenderId: "448284579599",
    appId: '1:448284579599:web:0248f1c4ac0d3dff95d07a'
  }
};
export const  ENV_S_CORE_SERVICE_API_V1_BASE = 'https://dev-apicore-cmlscukhsq-uc.a.run.app';
export const  S_CORE_SERVICE_API_PAYMENT_BASE_URL = 'https://staging-kuaa-api.tintosoft.co';
//  export const  S_CORE_SERVICE_API_PAYMENT_BASE_URL = 'http://localhost:8080';
// export const  ENV_S_CORE_SERVICE_API_V1_BASE = 'http://localhost:8080';
export const devActive = (ENV_S_CORE_SERVICE_API_V1_BASE.indexOf('https://dev') == 0 || ENV_S_CORE_SERVICE_API_V1_BASE.indexOf('https://loc') == 0)? true : false;

export class PodcastDTO {
    id:          string;
    name:        string;
    idClient:    string;
    title:       string;
    subtitle:    string;
    description: string;
    position:    number;
    banner1:     string;
    banner2:     string;
    banner3:     string;
    class1:      string;
    class2:      string;
    class3:      string;
    classImg1:   string;
    classImg2:   string;
    classImg3:   string;
    status:      string;

    moduleCode:  string;
    moduleName:  string;

    instructorCode: string;
    instructorName: string;

    contentType: string;
    estado: string;

}

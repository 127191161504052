<footer class="footer ">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>
                <li>
                    <a href="/">
                       Kuaa
                    </a>
                </li>
                <li>
                    <a href="/">
                        About Us
                    </a>
                </li>
                <li>
                    <a href="/">
                        Blog
                    </a>
                </li>
                <li>
                    <a href="/">
                        Licenses
                    </a>
                </li>
            </ul>
        </nav>
        <div class="copyright pull-right">
            &copy;
            {{test | date: 'yyyy'}}, content by
            <a href="/" target="_blank">Kuaa</a> .
        </div>
    </div>
</footer>

import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../../service/auth.service";
import { Router } from "@angular/router";
import { TokenService } from '../../../service/token.service';
import { ModuleService } from '../../../service/module.service';
import { ModulesDto } from '../../../model/modules-dto';
import { KuaaTransactionDto } from '../../../model/kuaa-transaction-dto';
import { KuaaTransactionGenericDto } from '../../../model/kuaa-transaction-generic-dto';
import { ToastrService } from 'ngx-toastr';
import { Helperutil } from '../../../common/helper/helperutil';
import { map, switchMap, debounceTime, catchError } from 'rxjs/operators';



let urlAdd;

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.css']
})
export class ModulesComponent implements OnInit {

  objListModules: ModulesDto[] = [];
  urlAdd ='moduleadd';
  showModalBox: boolean = false;
  sayHelloWorld: boolean = false;

  objKuaaTransactionDto: KuaaTransactionDto = new KuaaTransactionDto();
  objKuaaTransactionGenericDto: KuaaTransactionGenericDto = new KuaaTransactionGenericDto();

  entityTransfer: ModulesDto[] = []; 
  objModulesDto: ModulesDto = new ModulesDto();
  codeModelDelete: string;
  isSaveProcess: boolean = false;

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private objModuleService: ModuleService,
    private tokenService:TokenService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.loadModuleDefault();
  }


  loadModuleDefault(): void{
    const sToken = this.tokenService.getToken();
     this.objModuleService.getModuleList( sToken, 'f5557664-8332-45f8-90e1-7e9b3f371272' ).subscribe( 
       data => {
           this.objListModules = data ;
         },
         err => {
           console.log(err);
         }
     );
   };

   public open(code) {
     console.log('code: '+code);
    if(!code){
      // Dont open the modal
      this.showModalBox = false;
      this.objModulesDto =  new ModulesDto();
    } else {
       // Open the modal
       this.showModalBox = true;
       this.loadData(code);
    }
  }

  loadData(code){ 
    const sToken = this.tokenService.getToken();
    this.objModuleService.findById(  code, sToken ).subscribe( 
      data => {
          this.objKuaaTransactionGenericDto = data;
          if (this.objKuaaTransactionGenericDto.code == '00000') {
              this.entityTransfer = [];
              this.entityTransfer = this.objKuaaTransactionGenericDto.entityTransfer;
              this.objModulesDto =  new ModulesDto();
              this.objModulesDto = this.entityTransfer[0];
          }
        },
        err => {
          console.log(err);
        }
    );
  }

  goDelete(){
    this.objModulesDto
    this.toastr.info(  Helperutil.S_TOAS_PROCESS, Helperutil.S_TOAS_TITLE_HEAD, {
      timeOut: Helperutil.I_TOAS_TIMEOUT_PROCESS, positionClass: Helperutil.S_TOAS_POSITIONCLASS_TOP_RIGHT
    });  
    const sToken = this.tokenService.getToken();
    this.isSaveProcess = true;
    const http$ = this.objModuleService.deleteModule(this.objModulesDto, sToken);
    http$.pipe(
      map(el => {
        this.objKuaaTransactionDto = el;
        if (this.objKuaaTransactionDto.code == '00000') {          
          this.toastr.clear();
          this.toastr.success(this.objKuaaTransactionDto.message, Helperutil.S_TOAS_TITLE_HEAD, {
            timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_TOP_RIGHT
          });
          setTimeout(() => {            
            //this.router.navigate(['/modules']);
            window.location.reload();
            //this.isSaveProcess = false;
          }, 1000);
        }
        return el;
      })).subscribe(el => console.log(el),
        err => {
          if (err.error.error == Helperutil.S_SESSION_NOT_AUTORIZED) {
            this.isSaveProcess = false;
          } else {
            this.toastr.warning(err.error.mensaje, Helperutil.S_TOAS_TITLE_HEAD, {
              timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_BTN_RIGHT
            });
          }
        },
        () => console.log("Processing Complete.")
      );
  }

}

<div *ngIf="devActive" class="devactivo position-fixed" >
            <h4>Desarrollo activo </h4>
</div>
<div class="wrapper">
    <div class="sidebar" data-color="danger" data-background-color="white" data-image="https://storage.googleapis.com/publickuaa/publickuaa/assets/img/logo1.png">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background" style="background-image: url(./assets/img/logo_header.png)"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <div *ngIf="isMaps('maps')">
            <app-footer></app-footer>
        </div>
    </div>
    <!-- <div class="fixed-plugin">
        <div class="dropdown show-dropdown open ">
            <a href="#" data-toggle="dropdown" aria-expanded="true">
                <i class="fa fa-cog fa-2x"> </i>
            </a>
            <ul class="dropdown-menu " x-placement="bottom-start" style="position: absolute; top: 41px; left: -231px; will-change: top, left;">
                <li class="header-title"> Datos de prueba</li>
                <li class="adjustments-line">
                    <a href="javascript:void(0)" class="switch-trigger active-color">
                        <div class="ml-auto mr-auto">
                            <span class="badge filter badge-purple" data-color="purple"></span>
                            <span class="badge filter badge-azure" data-color="azure"></span>
                            <span class="badge filter badge-green" data-color="green"></span>
                            <span class="badge filter badge-orange" data-color="orange"></span>
                            <span class="badge filter badge-danger active" data-color="danger"></span>
                        </div>
                        <div class="clearfix"></div>
                    <div class="ripple-container"></div></a>
                </li>
                <li class="header-title">Contenidos Kuaa</li>
                <li>
                    <a class="img-holder switch-trigger" href="javascript:void(0)">
                        <img src="./assets/img/sidebar-1.jpg" alt="">
                    </a>
                </li>
                <li>
                    <a class="img-holder switch-trigger" href="javascript:void(0)">
                        <img src="./assets/img/sidebar-2.jpg" alt="">
                    <div class="ripple-container"></div></a>
                </li>
                <li>
                    <a class="img-holder switch-trigger" href="javascript:void(0)">
                        <img src="./assets/img/sidebar-3.jpg" alt="">
                    </a>
                </li>
                <li class="active">
                    <a class="img-holder switch-trigger" href="javascript:void(0)">
                        <img src="./assets/img/sidebar-4.jpg" alt="">
                    </a>
                </li>
                <li class="button-container">
                    <div>
                        <button class="btn btn-info btn-block btn-fill" data-toggle="modal" data-target="#buy">
                            
                        </button>
                    </div>
                </li>
                <li class="button-container">
                    <div>
                        <button class="btn btn-warning btn-block btn-fill" data-toggle="modal" data-target="#buy">
                            
                        </button>
                    </div>
                </li>
                <li class="button-container text-center" routerLinkActive="active">
                  <div>
                    <a class="btn btn-default btn-block" href="#">
                        
                    </a>
                  </div>
                </li>
            </ul>
        </div>
    </div> -->
</div>
<!-- Buy-Modal-angular -->
<div class="modal modal-angular fade" id="buy" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-body text-center">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="margin-top">
              
            </h4>
            <div class="separator"></div>
            <a href="#" class="modal-button" target="_blank">
                <div class="wrapper-card">
                    <div class="image-container">
                        <img src="./assets/img/logos/MARCA-KUAÁ-SIMPLIFICADA.png" alt="unloaded">
                    </div>
                    Plan
                    <div class="separator"></div>
                    <div class="product-type">
                        Kuaa
                    </div>
                </div>
            </a>
            <a href="#" class="modal-button" target="_blank">
                <div class="wrapper-card">
                    <div class="image-container image-angular-cli">
                        <img src="./assets/img/logos/MARCA-KUAÁ-SIMPLIFICADA.png" alt="unloaded">
                    </div>
                    Plan
                    <div class="separator"></div>
                    <div class="product-type">
                        Kuaa
                    </div>
                </div>
            </a>
            <h4>
                Kuaa PRO Version
            </h4>
            <div class="separator"></div>
            <a href="#" class="modal-button" target="_blank">
                <div class="image-container">
                    <img src="./assets/img/logos/MARCA-KUAÁ-SIMPLIFICADA.png" alt="unloaded">
                </div>
                kuaa
                <div class="separator"></div>
                <div class="price">
                    
                    <span>
                        $$$
                        <i class="fa fa-usd" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
            <a href="#" class="modal-button" target="_blank">
                <div class="image-container image-angular-cli">
                    <img src="./assets/img/logos/MARCA-KUAÁ-SIMPLIFICADA.png" alt="unloaded">
                </div>
                Kuaa
                <div class="separator"></div>
                <div class="price">
                    
                    <span>
                        $$
                        <i class="fa fa-usd" aria-hidden="true"></i>
                    </span>
                </div>
            </a>

            
        </div>
    </div>
</div>

</div>
